body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.kanban-container {
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 10px;
  position: relative;
}

.kanban {
  display: flex;
  flex-wrap: nowrap;
}

.kanban-column {
  flex: 0 0 auto;
  width: 250px;
  margin: 0 10px;
  background: #f4f4f4;
  border-radius: 8px;
  padding: 10px;
  position: relative;
}

.kanban-column-header {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
  background: #424242; /* Updated background color */
  color: white;
  border-radius: 8px 8px 0 0;
  padding: 10px 0;
  z-index: 10;
}

.kanban-column-header h2 {
  font-size: 16px;
  text-align: center;
  margin: 0;
}

.kanban-column-body {
  padding-top: 10px;
  max-height: 600px;
  overflow-y: auto;
}

.kanban-card {
  background: white;
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  word-break: break-word; /* Break long words to avoid overflow */
  white-space: normal; /* Ensure text wraps to the next line */
}

.kanban-card h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.kanban-card p {
  margin: 0;
  word-break: break-word; /* Break long words to avoid overflow */
  white-space: normal; /* Ensure text wraps to the next line */
}
